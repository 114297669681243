<template>
    <v-dialog v-model="dial" width="auto" persistent transition="dialog-bottom-transition">
        <v-card max-width="400" class="elevation-12 rounded-lg pa-4" color="white">
            <v-card-title class="d-flex align-center">
                <v-icon large color="red" class="mr-3">mdi-alert-circle-outline</v-icon>
                <span class="text-h6 font-weight-bold text-red-darken-3">Atención</span>
            </v-card-title>

            <v-card-text class="text-center text-body-1 font-weight-medium">
                {{ $store.state.dial_alert }}
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions class="justify-end">
                <v-btn color="info" class="white--text font-weight-bold px-4" @click="$store.commit('dial_alert', '')">
                    <v-icon left>mdi-check-circle</v-icon>
                    Ok
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: 'caja',

    data() {
        return {
            dial: false,
            countdown: 3, // Tiempo en segundos antes de cerrar
        };
    },
    created() {
        this.dial = true;

    },
    methods: {

    }
};
</script>
