import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import firebase from "firebase";
import store from "@/store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/panel",
    name: "panel",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "carrito" */ "../views/panel.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/lista_pagos",
    name: "lista_pagos",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/gestion_pagos/lista_pagos.vue"
      ),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/ingresa_pagos",
    name: "ingresa_pagos",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/gestion_pagos/ingresa_pagos.vue"
      ),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/miempresa",
    name: "miempresa",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "carrito" */ "../views/miempresa.vue"),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloempresa) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/soporte",
    name: "soporte",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "carrito" */ "../views/soporte.vue"),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloempresa) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/caja",
    name: "caja",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "carrito" */ "../views/ventas/caja.vue"),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulocaja) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/caja2",
    name: "caja2",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "carrito" */ "../views/caja2.vue"),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulocaja) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/caja_pc",
    name: "caja_pc",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "carrito" */ "../views/caja_pc.vue"),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulocaja) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/comprobantes_clientes/:bd/:ruc/:tipodoc/:serie/:correlativo/:cliente",
    name: "comprobantes_clientes",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/vistasLibres/comprobantes_clientes.vue"
      ),
  },
  {
    path: "/reporte_flujos",
    name: "reporte_flujos",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/ventas/reporte_flujos.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloclientes) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/flujocaja",
    name: "flujocaja",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "carrito" */ "../views/ventas/flujocaja.vue"),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulocaja) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/productos",
    name: "productos",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/productos/productos.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloproductos) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/movimientos_kardex",
    name: "movimientos_kardex",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/kardex/movimientos_kardex.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloproductos) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/costeo_receta",
    name: "costeo_receta",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/kardex/costeo_receta.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloproductos) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/costeo_insumos",
    name: "costeo_insumos",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/kardex/costeo_insumos.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloproductos) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/insumos",
    name: "insumos",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "carrito" */ "../views/kardex/insumos.vue"),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloproductos) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/categoriasinsumos",
    name: "categoriasinsumos",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/kardex/categoriasinsumos.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloproductos) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/recetas",
    name: "recetas",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "carrito" */ "../views/kardex/recetas.vue"),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloproductos) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/clientes",
    name: "clientes",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/clientes/clientes.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloclientes) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/empleados",
    name: "empleados",
    // route level code-splitting
    // this generate  s a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/empleados/empleados.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloclientes) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/revision",
    name: "revision",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/gestorempresas/revision.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.paneladmin) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/paneladmin",
    name: "paneladmin",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "carrito" */ "../views/paneladmin.vue"),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.paneladmin) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/accesos_usuarios/:id/",
    name: "accesos_usuarios",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "carrito" */ "../views/accesos_usuarios.vue"),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloempresa) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/panelgeneral",
    name: "panelgeneral",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/gestorempresas/panelgeneral.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.paneladmin) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/consultas_masivas",
    name: "consultas_masivas",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/gestorempresas/consultas_masivas.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.paneladmin) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/gestionEmpresas/:bd",
    name: "gestionEmpresas",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/gestorempresas/gestionEmpresas.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.paneladmin) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/categorias2",
    name: "categorias2",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/productos/categorias2.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloproductos) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/categorias",
    name: "categorias",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/productos/categorias.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloproductos) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/detalles_platos",
    name: "detalles_platos",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/productos/detalles_platos.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloproductos) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/carta2",
    name: "carta2",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "carrito" */ "../views/productos/productos2"),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloproductos) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/gestion_carta",
    name: "gestion_carta",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/carta_online/gestion_carta.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.baseDatos.restaurante) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/productos_carta/:id/:nombre",
    name: "productos_carta",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/carta_online/productos_carta.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.baseDatos.restaurante) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/carta/:id/:modo",
    name: "carta",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/carta_online/carta.vue"
      ),
  },
  {
    path: "/lista_mesas",
    name: "lista_mesas",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/restaurante/lista_mesas.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulomesas) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/comanda_mesa/:id/:res",
    name: "comanda_mesa",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/restaurante/comanda_mesa.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulomesas) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/reporte_pedidos",
    name: "reporte_pedidos",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/carta_online/reporte_pedidos"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulomesas) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/pedidos_app ",
    name: "pedidos_app",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/app_terceros/pedidos_app.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulomesas) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/pedidos_online ",
    name: "pedidos_online",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/carta_online/pedidos_online.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulomesas) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/delivery ",
    name: "delivery",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/restaurante/delivery.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulomesas) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/gestionDetalleDelivery/:id/:cliente",
    name: "gestionDetalleDelivery",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/restaurante/gestionDetalleDelivery.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulomesas) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/comprasClientes",
    name: "comprasClientes",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/clientes/comprasClientes.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloclientes) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/ventasxempleado",
    name: "ventasxempleado",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/reportes/ventasxempleado.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulocaja) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/comprobantes",
    name: "comprobantes",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "carrito" */ "../views/comprobantes.vue"),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloreportes) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/cocina",
    name: "cocina",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/restaurante/cocina.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulococina) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/barra",
    name: "barra",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/restaurante/barra.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulococina) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },

  {
    path: "/reportecocinav3",
    name: "reportecocinav3",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/reportes/reportecocinav3.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulococina) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/reportes",
    name: "reportes",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/reportes/reportes.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloreportes) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/boletaFactura",
    name: "boletaFactura",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/sunat/boletaFactura.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulosunat) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/nuevaNCredito",
    name: "nuevaNCredito",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/sunat/nuevaNCredito.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulosunat) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/creditoDebito",
    name: "creditoDebito",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/sunat/creditoDebito.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulosunat) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/resumenbaja",
    name: "resumenbaja",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/sunat/resumenbaja.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulosunat) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/ticket",
    name: "ticket",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "carrito" */ "../views/sunat/ticket.vue"),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulosunat) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/consolidaSunat",
    name: "consolidaSunat",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/sunat/consolidaSunat.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulosunat) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/ImportaExporta",
    name: "ImportaExporta",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "carrito" */ "../views/ImportaExporta.vue"),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloproductos) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/bajarCartaQR/:id",
    name: "bajarCartaQR",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/vistasLibres/bajarCartaQR.vue"
      ),
  },
  {
    path: "/buscardocumentos",
    name: "buscardocumentos",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/vistasLibres/buscardocumentos.vue"
      ),
  },
  {
    path: "/suministros",
    name: "suministros",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/vistasLibres/suministros.vue"
      ),
  },
  {
    path: "/gestionUsuarios",
    name: "gestionUsuarios",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "carrito" */ "../views/gestionUsuarios.vue"),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.moduloempresa) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/reporteventa",
    name: "reporteVentaDetalle",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/reportes/reporteVentaDetalle.vue"
      ),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/reporteoperaciones",
    name: "reporteoperaciones",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/reportes/reporteoperaciones.vue"
      ),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/reporte_proformas",
    name: "reporte_proformas",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/ventas/reporte_proformas.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulocaja) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
  {
    path: "/habitaciones",
    name: "habitaciones",
    // route level code-splitting
    // this generates a separate chunk (Produc.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "carrito" */ "../views/hoteles/habitaciones.vue"
      ),
    meta: {
      requireAuth: true,
    },
    beforeEnter: (to, from, next) => {
      if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
        if (store.state.permisos.modulocaja) {
          next();
        } else {
          next({
            name: "panel",
          });
        }
      }
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.onReady(() => {
  const lastRoute = localStorage.getItem("lastRoute");

  if (lastRoute && firebase.auth().currentUser) {
    router.replace(lastRoute);
  }
});

router.beforeEach((to, from, next) => {
  const user = firebase.auth().currentUser;

  if (to.fullPath) {
    localStorage.setItem("lastRoute", to.fullPath);
  }
  // Si el usuario está logueado y trata de acceder a "/", redirigirlo al panel
  if (user && to.path === "/") {
    next({ name: "panel" });
  } else if (to.matched.some((ruta) => ruta.meta.requireAuth)) {
    // Si la ruta requiere autenticación y el usuario está logueado, permitir acceso
    if (user) {
      next();
    } else {
      // Si no está logueado, redirigir a Home
      next({ name: "Home" });
    }
  } else {
    // Si la ruta no requiere autenticación, continuar normalmente
    next();
  }
});

export default router;
